<template>
    <div style="width: 100%;">
        <van-dropdown-menu>
            <van-dropdown-item v-model="birth.day" :class="requiredDay ? 'requiredDay' : ''"
                :title="birth.day == '' ? $t('registration.formValueDay') : birth.day" :options="dayOption"
                @change="handleBirthChange" />
            <van-dropdown-item v-model="birth.month" :class="requiredMonth ? 'requiredMonth' : ''"
                :title="birth.month == '' ? $t('registration.formValueMonth') : birth.month" :options="monthOption"
                @change="handleBirthChange" />
            <van-dropdown-item v-model="birth.year" :class="requiredYear ? 'requiredYear' : ''"
                :title="birth.year == '' ? $t('registration.formValueYear') : birth.year" :options="yearOption"
                @change="handleBirthChange" />
        </van-dropdown-menu>
    </div>
</template>
  
<script>
import {
    DropdownMenu,
    DropdownItem,
} from 'vant';

export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    props: {
        birth: {
            type: Object,
            default: () => {
                return {
                    day: '',
                    month: '',
                    year: ''
                };
            },
        },
        requiredDay: {
            type: Boolean,
            default: false
        },
        requiredMonth: {
            type: Boolean,
            default: false
        },
        requiredYear: {
            type: Boolean,
            default: false
        },
        country: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dayOption: [
                { text: '01', value: '01' },
                { text: '02', value: '02' },
                { text: '03', value: '03' },
                { text: '04', value: '04' },
                { text: '05', value: '05' },
                { text: '06', value: '06' },
                { text: '07', value: '07' },
                { text: '08', value: '08' },
                { text: '09', value: '09' },
                { text: '10', value: '10' },
                { text: '11', value: '11' },
                { text: '12', value: '12' },
                { text: '13', value: '13' },
                { text: '14', value: '14' },
                { text: '15', value: '15' },
                { text: '16', value: '16' },
                { text: '17', value: '17' },
                { text: '18', value: '18' },
                { text: '19', value: '19' },
                { text: '20', value: '20' },
                { text: '21', value: '21' },
                { text: '22', value: '22' },
                { text: '23', value: '23' },
                { text: '24', value: '24' },
                { text: '25', value: '25' },
                { text: '26', value: '26' },
                { text: '27', value: '27' },
                { text: '28', value: '28' },
                { text: '29', value: '29' },
                { text: '30', value: '30' },
                { text: '31', value: '31' }
            ],
            monthOption: [
                { text: '01', value: '01' },
                { text: '02', value: '02' },
                { text: '03', value: '03' },
                { text: '04', value: '04' },
                { text: '05', value: '05' },
                { text: '06', value: '06' },
                { text: '07', value: '07' },
                { text: '08', value: '08' },
                { text: '09', value: '09' },
                { text: '10', value: '10' },
                { text: '11', value: '11' },
                { text: '12', value: '12' }
            ],
            // 大于18岁的100年
            yearOption: Array.from(Array(100), (v, k) => k + new Date().getFullYear() - 18 - 99).reverse().map(i => { return { text: i.toString(), value: i.toString() } }),
        };
    },
    methods: {
        handleBirthChange() {
            this.$emit('change', this.birth);
        }
    }
};
</script>
  
<style lang="less" scoped>
/deep/ .requiredDay::before {
    position: absolute;
    left: -4%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    z-index: 100;
}

/deep/ .requiredMonth::before {
    position: absolute;
    left: 30%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    z-index: 100;
}

/deep/ .requiredYear::before {
    position: absolute;
    left: 63%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    z-index: 100;
}
</style>
  